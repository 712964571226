import 'ress'
import 'typeface-lato'

import React from 'react'
import styled, { css, createGlobalStyle } from 'styled-components'

import theme from '../../config/theme'

export const style = css`
  background-color: ${theme.primary.bg};
  color: ${theme.primary.fg};
  height: 100%;

  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
  line-height: 1rem;
`

export const Root = styled.div`
  ${style};
`

export const GlobalStyle = createGlobalStyle`
  html, body { 
    ${style}

    @media all and (max-width: 414px) {
      font-size: 14.5px;
    }
    @media all and (max-width: 375px) {
      font-size: 13px;
    }
    @media all and (max-width: 320px) {
      font-size: 11px;
    }
  }

  #___gatsby, #___gatsby > * {
    height: 100%;
    min-height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:visited {
      color: inherit;
    }
  }
  
`

export const Viewport = props => (
  <>
    <GlobalStyle />
    <Root {...props} />
  </>
)

export default Viewport
