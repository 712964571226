const _ = require('lodash')

const invert = ({ 0: a, 1: b, fg, bg } = {}) =>
  a || b ? [b, a] : fg || bg ? { fg: bg, bg: fg } : null

// let colors = [
//   {
//     bg: '#151429',
//     fg: '#6f7ea9',
//   },
//   {
//     bg: '#202b4b',
//     fg: '#8393bd',
//   },
//   {
//     bg: '#313c66',
//     fg: '#99a8d3',
//   },
//   {
//     bg: '#3f4872',
//     fg: '#a7bbdf',
//   },
//   {
//     bg: '#4d5988',
//     fg: '#cbd4e5',
//   },
// ]

let colors = [
  {
    bg: '#f6f8fc',
    fg: '#2e385c',
  },
  {
    bg: '#3430ba',
    fg: '#f6f1ec',
  },
  {
    bg: '#2e385c',
    fg: '#f6f8fc',
  },
]

const grayscale = [
  '#151429',
  '#202b4b',
  '#2a3a75',
  '#364c89',
  '#405ca9',
  '#526ebb',
  '#5b82de',
  '#7e98df',
  '#a1adec',
  '#b4caf2',
  '#e0e4f9',
]

const theme = {
  primary: colors[0],
  secondary: colors[1],
  tertiary: colors[2],
  accent: colors[1],
}

const breakpoints = (['40em', '52em', '64em'],
['375px', '640px', '832px', '1024px'])

module.exports = {
  breakpoints,
  theme,
  invert,
  colors,
  grayscale,
  pairs: colors,
  ...theme,
}
